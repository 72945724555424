<template>
    <div class="card">
        <div v-if="isProcessing">
            <Processing />
        </div>
        <div v-else-if="users.length > 0" class="listview listview--bordered">
            <div class="listview__item" v-for="(user, i) in users" :key="i">
                <img class="listview__image" alt="" @click="view(user.id)" :src="user.photo != '' ? user.photo : 'img/placeholder.jpg' ">
                <div class="listview__content">
                    <div class="listview__heading" @click="view(user.id)">{{ user.fullname }}</div>
                    <p>{{ user.email }}</p>
                    <p>{{ user.role }}</p>
                    <p>{{ user.telephone }}</p>
                    <p>{{ user.dob }}</p>
                </div>
                <div class="actions listview__actions">
                    <i class="actions__item zmdi zmdi-edit" @click="edit(user.id)"></i>
                    <i class="actions__item zmdi zmdi-eye" @click="view(user.id)"></i>
                </div>
            </div>
        </div>
        <div class="notfound" v-else>No users found</div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            users: [],
            year: this.$store.state.devotionalYear,
            isProcessing: false,
        }
    },
    created(){
        this.loadUsers()
    },
    methods: {

        async loadUsers(){
            this.isProcessing = true
            const logs = []
            const snapshot = await this.$db.collection("users").orderBy("createdAt", "desc").get()
            snapshot.forEach(doc => {
                const data = doc.data()
                if (data.createdAt) {
                    data.dob = data.dob && data.dob != null ? this.$moment(data.dob.toDate()).format('DD-MM-YYYY') : "NIL"
                    data.createdAt = this.$moment(data.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss a")
                }
                logs.push({ id: doc.id, ...data })
            })
            this.users = logs
            this.isProcessing = false
        },

        async load(){
            this.isProcessing = true
            const logs = []
            const snapshot = await this.$db.collection("news").orderBy("createdAt", "desc").get()
            snapshot.forEach(doc => {
                const data = doc.data()
                if (data.createdAt) {
                    data.createdAt = this.$moment(data.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss a")
                }
                logs.push({ id: doc.id, ...data })
            })
            this.news = logs
            this.isProcessing = false
        },
        view(id){
            this.$router.push("/users/u/" + id)
        },
        edit(id){
            this.$router.push("/users/u/" + id + "/edit")
        },
        remove(id, i){
            console.log(id, i);
        },
    },
    components: {
        "Processing": () => import("@/components/Loaders/Processing.vue"),
    }
}
</script>

<style scoped>
.listview__image {
    margin-right: 20px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}
</style>